// @see: https://github.com/gatsbyjs/gatsby/tree/master/examples/using-redux

import React from "react"
import { Provider } from "react-redux"
import createStore from "./src/s4n/state/createStore"

import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';

// @see: https://github.com/supasate/connected-react-router#step-3
import { ConnectedRouter } from 'connected-react-router'

import { StaticRouter } from 'react-router'

import { cartFetch, setCartToken } from './src/components/s4n/Shop/ext/actions'

import { createBrowserHistory } from 'history'


// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore()

    // const history = createBrowserHistory();
    // let history = null;
// gatsby-browser.js START
    if (`null` !== process.env.SOFT4NET_SHOP_PAGE_DRUPAL_ID) {
        if (typeof localStorage !== `undefined`) {
            store.dispatch(setCartToken(localStorage.getItem('cartToken') || Math.random().toString(36).substr(2)));
            store.dispatch(cartFetch());
    
            // history = createBrowserHistory();
        }
    }
// gatsby-browser.js STOP

    return (
        <Provider store={store}>
            <ApolloProvider client={client}>
                {/* <ConnectedRouter history={history}> */}
                    <StaticRouter>
                        {element}
                    </StaticRouter>
                {/* </ConnectedRouter> */}
            </ApolloProvider>
        </Provider>
    );
}