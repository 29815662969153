// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/scrollspy'

import wrapWithProvider from "./wrap-with-provider"
export const wrapRootElement = wrapWithProvider

// const $ = require("jquery")
// export const onInitialClientRender = () => {
//     $(document).ready(function () {
//         console.log("The answer is don't think about it!")
//     });
// }